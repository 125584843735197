
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Button } from 'antd/lib'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import LogoSvg from 'public/logo.svg'
import styles from './index.module.css'

export default function NotFound() {
  const { t } = useTranslation('404')
  const router = useRouter()

  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t('title')}</h1>
        <h2 className={styles.subtitle}>{t('subtitle')}</h2>
        <p>{t('desc')}</p>
        <Button
          icon={<Icon component={IconNames.ARROW_LEFT} size={15} />}
          size="small"
          className={styles.back__button}
          onClick={() => router.back()}
        >
          {t('goBack')}
        </Button>
      </div>

      <LogoSvg className={styles.logo} />
    </div>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  